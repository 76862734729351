import moment from 'moment';

import './printer.css';

export function PrinterLabel({ currentStock, labelToPrintRef }) {
  return (
    <div className="printer-element p-5" ref={labelToPrintRef}>
      <p style={{ fontSize: '64px' }}>
        <strong>
          {currentStock.seqproduto} - {currentStock.produto}
        </strong>
      </p>
      <p style={{ fontSize: '64px' }}>
        EMBALAGEM: <strong>{currentStock.volume}</strong>
      </p>

      <div>
        <p style={{ fontSize: '64px' }}>
          LOTE:{' '}
          <strong className="lote" style={{ fontSize: '104px' }}>
            {currentStock.lote}
          </strong>
        </p>
      </div>
      <p style={{ fontSize: '64px' }}>
        ENTRADA:{' '}
        <strong style={{ fontSize: '104px' }}>{moment(currentStock.dtaentrada).format('DD/MM/YYYY')}</strong>
      </p>
    </div>
  );
}
