export function UserTempPasswordModal({ supplierRegistered }) {
  
  return (
    <div
      className="modal fade"
      id="userTempPassword"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 
              className="modal-title fs-5" 
              id="staticBackdropLabel"
            >
              Senha temporária
            </h1>
            <button
              type="button" 
              className="btn btn-close" 
              data-bs-dismiss="modal" 
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body">
            <div className="col">
              <div className="form-group">
                <input
                  className="form-control"
                  value={supplierRegistered?.passwordcad}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
